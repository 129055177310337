import React from "react";
import "../styles/Partners.css";
import organizer1 from "../assets/partners/organizer1.png";
import organizer2 from "../assets/partners/organizer2.png";
import partner1 from "../assets/partners/image.png";
import partner2 from "../assets/partners/image-1.png";
import partner4 from "../assets/partners/tech_partners1.png";
import partner5 from "../assets/partners/tech_partners2.png";
import partner6 from "../assets/partners/tech_partners3.png";
import partner7 from "../assets/partners/support1.png";
import partner8 from "../assets/partners/support2.png";
import partner9 from "../assets/partners/support3.png";
import partner10 from "../assets/partners/support4.png";
import partner11 from "../assets/partners/support5.png";
import partner12 from "../assets/partners/support6.png";
import partner13 from "../assets/partners/support7.png";
import partner14 from "../assets/partners/support8.png";
import partner15 from "../assets/partners/support9.png";
import partner16 from "../assets/partners/support10.png";
import logo from "../assets/partners/logo.png";

import { Link } from "react-router-dom";

function Partners() {
  return (
    <div className="partners">
      <div className="partners__header">
        <h1 className="partners__title">ПАРТНЁРЫ</h1>
      </div>
      <div className="partners__background">
        <div className="partners__content">
          <div className="partners__organizers">
            <h2 className="partners__subtitle">Организаторы</h2>
            <div className="partners__organizers-border">
              <div className="partners__organizers-content">
                <Link to="https://nastavnikirossii.ru" target="_blank">
                  <img
                    src={organizer1}
                    alt="Nastavniki"
                    className="partners__organizer-image partners__organizer-image--nastavniki"
                  />
                </Link>
                <Link to="https://aciso.ru" target="_blank">
                  <img
                    src={organizer2}
                    alt="Arsib"
                    className="partners__organizer-image partners__organizer-image--arsib"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="partners__join">
            <div className="partners__join-border">
              <div>
                <h3 className="partners__join-title">
                  Стань частью VIII Кубка CTF
                </h3>
                <p className="partners__join-text">
                  Приглашаем к сотрудничеству ИТ и ИБ-компании. Напишите нам,
                  если хотите поддержать развитие сообщества информационной
                  безопасности России.
                </p>
                <Link to="mailto:info@aciso.ru">
                  <button className="partners__join-button">
                    обсудить сотрудничество
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="partners__official">
          <h2 className="partners__official-title">Официальный партнёр</h2>
          <div className="partners__official-border">
            <div>
              <Link to="https://www.sberbank.com/ru/ecs" target="_blank">
                <img
                  src={partner1}
                  alt="Sber"
                  className="partners__partner-image"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="partners__tech-section">
          <div className="partners__partners-section">
            <h2 className="partners__subtitle">Партнёры</h2>
            <div className="partners__partners-border">
              <div>
                <Link to="https://cctld.ru" target="_blank">
                  <img
                    src={partner2}
                    alt="CCtld"
                    className="partners__partner-image"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="partners__tech-partners-section">
            <h2 className="partners__tech-subtitle">
              Технологические партнёры
            </h2>
            <div className="partners__tech-partners-border">
              <div className="partners__tech-partners-content">
                <Link
                  to="https://cbsctf.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={partner4}
                    alt="CBS logo"
                    className="partners__tech-partner-image"
                  />
                </Link>
                <Link
                  to="https://ntc-vulkan.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={partner5}
                    alt="Partner 5"
                    className="partners__tech-partner-image"
                  />
                </Link>
                <img
                  src={partner6}
                  alt="Phase logo"
                  className="partners__tech-partner-image"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="partners__support-section">
          <h2 className="partners__support-subtitle">При поддержке</h2>
          <div className="partners__support-border">
            <div className="partners__support-grid">
              <img
                src={partner7}
                alt="Partner 7"
                className="partners__support-image partners__support-image-1"
              />
              <img
                src={partner8}
                alt="Partner 8"
                className="partners__support-image partners__support-image-2"
              />
              <img
                src={partner9}
                alt="Partner 9"
                className="partners__support-image partners__support-image-3"
              />
              <img
                src={partner10}
                alt="Partner 10"
                className="partners__support-image partners__support-image-4"
              />
              <img
                src={partner11}
                alt="Partner 11"
                className="partners__support-image partners__support-image-5"
              />
              <img
                src={partner12}
                alt="Partner 12"
                className="partners__support-image partners__support-image-6"
              />
              <img
                src={partner13}
                alt="Partner 13"
                className="partners__support-image partners__support-image-7"
              />
              <img
                src={partner14}
                alt="Partner 14"
                className="partners__support-image partners__support-image-8"
              />
              <img
                src={partner15}
                alt="Partner 15"
                className="partners__support-image partners__support-image-9"
              />
              <img
                src={partner16}
                alt="Partner 16"
                className="partners__support-image partners__support-image-10"
              />
            </div>
          </div>
        </div>

        <div className="partners__info-section">
          <div className="partners__info-border">
            <div className="partners__info-content">
              <div className="partners__info-icon">
                <img src={logo} />
              </div>
              <p className="partners__info-text">
                Кубок CTF ежегодно собирает несколько тысяч молодых
                айти-специалистов: в отборочном туре участвуют свыше 300 команд
                студентов и молодых профессионалов, а финал помогает выявить
                самых талантливых участников. Поддержка такого мероприятия
                помогает с развитием HR-бренда, повышает узнаваемость компании и
                закрывает маркетинговые потребности организаций любого масштаба.
              </p>
            </div>
          </div>
        </div>

        <div className="partners__decorations">
          <div className="partners__decoration partners__decoration--red"></div>
          <div className="partners__decoration partners__decoration--blue"></div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
