import React, { useState, useEffect } from 'react';
import '../styles/Registration.css';

function RegistrationForeign() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Имитация загрузки в течение 2 секунд

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="registration-ru">
      <h1>Registration of foreign teams</h1>
      <div className="registration-form-container">
        {isLoading && (
          <div className="loader">
            <div className="spinner"></div>
          </div>
        )}
        <iframe 
          style={{
            border: 'none', 
            width: '100%', 
            height:'3550px',
            display: isLoading ? 'none' : 'block'
          }} 
          id="network-access-request-form-l7uqma" 
          src="https://register.ctfcup.ru/forms/stfcup-2024-oi4jgj"
          title="Registration Form"
        ></iframe>
      </div>
    </div>
  );
}

export default RegistrationForeign;