import React from "react";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import positionsPdf from "../assets/positions.pdf";
import regulationsPdf from "../assets/regulations.pdf";

function Home() {
  return (
    <div className="home">
      <h1 className="home__brand-name">КУБОК CTF</h1>
      <div className="home__brand-year">2024</div>
      <div className="home__description">
        <section className="registration-block">
          <div className="registration">
            <div className="registration__title">РЕГИСТРАЦИЯ</div>
            <p className="registration__subtitle">
              <Link to="/registration-ru">
                <span className="registration__ru">российских команд</span>
              </Link>{" "}
              <span className="registration__slash">/</span>
              <Link to="/registration-foreign">
                <span className="registration__foreign">foreign teams</span>
              </Link>
            </p>
          </div>
        </section>
        <section className="about">
          <h2 className="about__title">ЧТО ТАКОЕ CTF?</h2>
          <div className="about__description">CTF (Capture the Flag)</div>
          <div className="about__text">
            <p>— это соревнования по информационной безопасности</p>
            <p>в форме командной игры, главная цель которой — захватить</p>
            <p>флаг у соперника в приближенных к реальным условиях.</p>
          </div>
        </section>
        
        <section className="place">
          <div className="place__item"></div>
          <div className="place__description">Место проведения уточняется</div>
        </section>
        <section className="documents">
          <h2 className="documents__title">
            ОФИЦИАЛЬНЫЕ <br />
            ДОКУМЕНТЫ
          </h2>
          <div className="documents__items">
            <div className="documents__item">
              <a
                href={positionsPdf}
                target="_blank"
                rel="noopener noreferrer"
                className="documents__item--icon-link"
              >
                <div className="documents__item--icon documents__item--icon-red"></div>
              </a>
              <h3 className="documents__item--title">Положение</h3>
              <div className="documents__item--description">
                О проведении Всероссийских соревнований в области информационной
                безопасности «Кубок CTF России»
              </div>
            </div>
            <div className="documents__item">
              <a
                href={regulationsPdf}
                target="_blank"
                rel="noopener noreferrer"
                className="documents__item--icon-link"
              >
                <div className="documents__item--icon documents__item--icon-blue"></div>
              </a>
              <h3 className="documents__item--title">Регламенты</h3>
              <div className="documents__item--description">
                Правила проведения отборочного этапа VIII Кубка CTF России
              </div>
            </div>
            <div className="documents__item">
              <div className="documents__item--icon documents__item--icon-red"></div>
              <h3 className="documents__item--title">Памятка</h3>
              <div className="documents__item--description">
                Появится ближе к дате очных этапов
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Home;
