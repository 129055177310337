import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Information from './pages/Information';
import Team from './pages/Team';
import Jury from './pages/Jury';
import Partners from './pages/Partners';
import './styles/global.css';
import RegistrationRu from './pages/RegistrationRU';
import RegistrationForeign from './pages/RegistrationForeign';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/information" element={<Information />} />
          <Route path="/team" element={<Team />} />
          <Route path="/jury" element={<Jury />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/registration-ru" element={<RegistrationRu />} />
          <Route path="/registration-foreign" element={<RegistrationForeign />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;