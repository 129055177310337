import React from 'react';
import '../styles/Jury.css';
import image1 from '../assets/jury/image.png';
import image2 from '../assets/jury/image-1.png';
import image3 from '../assets/jury/image-2.png';
import image4 from '../assets/jury/image-3.png';
import image5 from '../assets/jury/image-4.png';
import image6 from '../assets/jury/image-5.png';
import image7 from '../assets/jury/image-6.png';
import image8 from '../assets/jury/image-7.png';
import image9 from '../assets/jury/image-8.png';
import image10 from '../assets/committee/masalovich.png';
import image11 from '../assets/jury/image-10.png';
import image12 from '../assets/jury/image-11.png';
import image13 from '../assets/jury/image-12.png';
import image14 from '../assets/jury/image-13.png';

const juryMembers = [
  {
    name: 'БУДНИКОВ АЛЕКСАНДР',
    position: 'Управляющий директор по информационной безопасности, ПАО АФК «Система»',
    image: image1
  },
  {
    name: 'БУГРОВ ВЛАДИМИР',
    position: 'Заместитель начальника Департамента технической защиты информации Главного научно-исследовательского вычислительного центра Управления делами Президента Российской Федерации',
    image: image13
  },
  {
    name: 'ВОЛКОВ СЕРГЕЙ',
    position: 'Начальник отдела информационной безопасности, АО «ГОЗНАК»',
    image: image2
  },
  {
    name: 'ДЕРЛЫШ ИЛЬЯ',
    position: 'Эксперт информационной безопасности, Росатом',
    image: image14
  },
  {
    name: 'КАДЕР МИХАИЛ',
    position: 'Архитектор по информационной безопасности, Positive Technologies',
    image: image3
  },
  {
    name: 'КУЗНЕЦОВ АНДРЕЙ',
    position: 'Технический директор Национального Киберполигона «Ростелеком-Солар»',
    image: image6
  },
  {
    name: 'МАРКОВ АЛЕКСЕЙ',
    position: 'Президент группы компаний ГК «Эшелон», доктор технических наук, лауреат премии Правительства РФ в области науки и техники',
    image: image5
  },
  {
    name: 'МИНАКОВ СЕРГЕЙ',
    position: 'Заместитель генерального директора АНО НТЦ ЦК, старший научный сотрудник Академии криптографии РФ',
    image: image8
  },
  {
    name: 'МАСАЛОВИЧ АНДРЕЙ',
    position: 'Генеральный директор ООО «Лавина Пульс»',
    image: image10
  },
  {
    name: 'МИХАЙЛЕНКО НАТАЛЬЯ',
    position: 'Доцент кафедры противодействия преступлениям в сфере ИТТ, Московский университет МВД России им. В.Я. Кикотя, кандидат юридических наук, доцент Вице-президент Московского регионального отделения Международной полицейской ассоциации',
    image: image11
  },
  {
    name: 'ЛОСКУТОВА ОЛЕСЯ',
    position: 'Заместитель начальника центрального вычислительного центра, Главный информационно-аналитический центр МВД России',
    image: image12
  },
  {
    name: 'ОВЧИННИКОВ ВИКТОР',
    position: 'Начальник отдела информационной безопасности, ООО «М13 ИТ УСЛУГИ»',
    image: image9
  },
  {
    name: 'СКЛЯРОВ ДМИТРИЙ',
    position: 'Руководитель отдела анализа приложений, Positive Technologies',
    image: image4
  },
  {
    name: 'ЦАРЕВ ГРИГОРИЙ',
    position: 'Руководитель направления Центра взаимодействия и реагирования Департамента информационной безопасности Банка России',
    image: image7
  }
];

function Jury() {
  return (
    <div className="jury">
      <h1 className="jury__title">ЖЮРИ</h1>
      <div className="jury__members">
        {juryMembers.map((member, index) => (
          <div key={index} className="jury__member">
            <img src={member.image} alt={member.name} className="jury__member-image" />
            <div className="jury__member-info">
              <h2 className="jury__member-name">{member.name}</h2>
              <p className="jury__member-position">{member.position}</p>
            </div>
          </div>
        ))}
        <div className="jury__member"></div>
        <div className="jury__member"></div>
      </div>
    </div>
  );
}

export default Jury;