import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Header.css";
import logo from "../assets/logo.png";

function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
      if (currentScrollPos > prevScrollPos) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header ${visible ? 'header--visible' : 'header--hidden'}`}>
      <div className="header__content">
        <Link to="/" className="header__logo-link">
          <img src={logo} alt="CTF Cup" className="header__logo" />
        </Link>
        <Link to="/registration-ru" className="header__registration">
          РЕГИСТРАЦИЯ
        </Link>
        <button className="header__menu-toggle" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={`header__nav ${isMenuOpen ? 'header__nav--open' : ''}`}>
          <Link to="/" className="header__nav-link">Главная</Link>
          <Link to="/information" className="header__nav-link">Информация</Link>
          <Link to="/team" className="header__nav-link">Команда</Link>
          <Link to="/jury" className="header__nav-link">Жюри</Link>
          <Link to="/partners" className="header__nav-link">Партнёры</Link>
        </nav>
      </div>
      <div className="header__line"></div>
    </header>
  );
}

export default Header;